@import '../../styles/NLRTM-font-family';
@import '../../styles/variables';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  background: $theme-background-color;
  font-family: $font-family-headings;
  font-size: $default-font-size;
  font-weight: normal;
  color: $theme-color-text;
}

body {
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
  font-weight: normal;
}

input,
textarea,
select,
button {
  font-weight: normal;
  background: transparent;
  border: none;
  margin: 0;
}

strong,
th {
  font-weight: normal;
  font-family: $font-family-strong;
}

:global(#root) {
  height: 100%;
}

.root {
  position: relative;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  height: 100%;

  display: flex;
  flex-direction: column;
}
