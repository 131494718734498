@import '../../styles/variables';
@import '../../styles/typography';
@import '../../styles/spacing.scss';

.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  flex-grow: 1;
  flex-shrink: 1;

  .content {
    max-width: 400px;
    margin: 0 auto;
    padding: spacing(12) 0;
    text-align: center;
    @include typography();

    color: $theme-color-text;

    .detail {
      font-family: $font-family-strong;
      font-size: 16px;
      color: $darkred;
    }

    a {
      font-size: 14px;
      color: $theme-color-text;
    }
  }
}
