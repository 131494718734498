@import '../../styles/variables.scss';
@import '../../styles/spaced.scss';
@import '../../styles/spacing.scss';
@import '../../components/Header/variables.scss';

.websocketStatus {
  height: 100%;
  display: flex;
  align-items: center;
  @include spaced-horizontal(spacing(2));
}

.indicator {
  flex-grow: 0;
  flex-shrink: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  &.ok {
    background-color: $green;
  }

  &:not(.ok) {
    background-color: $red;
  }
}

.indicatorText,
.text {
  font-size: $header-action-font-size;
}

.indicatorText {
  font-family: $font-family-strong;
}

.text {
  font-family: $font-family-regular;
}
