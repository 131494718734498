@import '../../styles/variables.scss';
@import '../../styles/strip-unit.scss';

$header-background: $theme-head-header-background;
$header-color: $theme-header-color;
$header-height: 50px;
$header-font-size: 20px;
$header-action-font-size: 14px;
$header-menu-border-color: #f2f1f1;
$visualization-height: 40px;
$jit-monitor-height: 34px;
$header-button-border-width: 1px;
$sub-header-height: 48px;
$sub-header-border: 1px solid $lightgrey;
