@import '../../styles/variables';
@import '../../styles/mixins';

@mixin loaderIcon($textColor, $mainBorderColor, $secondaryBorderColor, $iconColor) {
  // Apparentlyy SVG's path fill of an background-image doesn't work with HEX colours
  // https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#Color_keywords
  // It works with name colours and rgb()
  // With the following hack we get the rgb() notation for the desided color.
  // The opacity is needed. If 1 is set and the input color is a color name, Sass won't output rgb() nototation but a color name
  $iconColorAsRGB: rgba($iconColor, 0.9999);
  &.loading {
    .icon {
      @include spinner(40px, 10px, $mainBorderColor, $secondaryBorderColor);
    }
  }
  &.finished {
    .icon {
      $image: url("data:image/svg+xml;utf8,<svg width='21' height='16' xmlns='http://www.w3.org/2000/svg'><path d='m2.126,6.001l-2.126,2.116l7.066,7.097l13.06,-13.096l-2.125,-2.118l-10.933,10.964l-4.942,-4.963z' fill='#{$iconColorAsRGB}'/></svg>");
      @include circumferenceWithImage(40px, 10px, $mainBorderColor, $image);
    }
  }
  &.error {
    .icon {
      $image: url("data:image/svg+xml;utf8,<svg fill='%23000000' height='48' viewBox='0 0 24 24' width='48' xmlns='http://www.w3.org/2000/svg'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' fill='#{$iconColorAsRGB}'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      @include circumferenceWithImage(40px, 10px, $mainBorderColor, $image);
    }
  }
  .message {
    color: $textColor;
  }
}

.loader {
  .spinnerWrapper {
    @include centerWrapper;
  }

  .message {
    text-align: center;
  }

  &.light {
    @include loaderIcon($black, $mediumgrey, $black, $mediumgrey);
  }

  &.dark {
    @include loaderIcon($white, $white, transparent, $white);
  }
}
