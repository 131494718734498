@import '../../styles/variables.scss';
@import '../../styles/spaced.scss';
@import '../../styles/spacing.scss';
@import '../../styles/fontSize';
@import '../../styles/mixins';
@import '../../styles/z-indices.scss';
@import './variables.scss';

.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
}

.popup {
  position: absolute;
  box-sizing: border-box;
  font-family: $font-family-regular;
  font-size: $fontSizeModal;
  color: $theme-color-text;
  z-index: $displayOptionPopupIndex;

  &.fadeIn {
    @include fadeIn();
  }

  // top
  &.topCenter,
  &.topLeft,
  &.topRight {
    bottom: 100%;
  }

  // bottom
  &.bottomLeft,
  &.bottomRight {
    top: 100%;
  }

  // left
  &.topLeft,
  &.bottomLeft {
    right: -#{$tooltipHelpIconSize / 2}px !important;
  }

  // right
  &.topRight,
  &.bottomRight {
    left: -#{$tooltipHelpIconSize / 2}px !important;
  }

  // center
  &.topCenter,
  &.bottomCenter {
    align-items: center;
  }
}

.helpIconWrapper {
  width: 100%;
  transition: color 0.3s;
}

.helpIconWrapper:hover {
  cursor: pointer;
  color: $darkblue;
}

.helpIcon {
  vertical-align: middle;
  font-size: #{$tooltipHelpIconSize}px;
}

.closeIconWrapper {
  order: 1;
}

.closeIcon {
  vertical-align: middle;
  font-size: 18px;
  color: $darkblue;
  padding: spacing(2);

  &:hover {
    cursor: pointer;
  }
}

.triangleClip {
  // top
  &.topCenter,
  &.topLeft,
  &.topRight {
    order: 1;
  }
  // center
  &.topCenter,
  &.bottomCenter {
    margin: 0 !important;
  }
}

.triangle {
  // top
  &.topCenter,
  &.topLeft,
  &.topRight {
    margin-top: -10px !important;
  }
}
