@font-face {
  font-family: 'NLRTM-Bold';
  src: url('../assets/fonts/NLRTM-Bold.eot');
  src: url('../assets/fonts/NLRTM-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/NLRTM-Bold.otf') format('otf'), url('../assets/fonts/NLRTM-Bold.woff2') format('woff2'),
    url('../assets/fonts/NLRTM-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NLRTM-Medium';
  src: url('../assets/fonts/NLRTM-Medium.eot');
  src: url('../assets/fonts/NLRTM-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/NLRTM-Medium.otf') format('otf'), url('../assets/fonts/NLRTM-Medium.woff2') format('woff2'),
    url('../assets/fonts/NLRTM-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NLRTM-Regular';
  src: url('../assets/fonts/NLRTM-Regular.eot');
  src: url('../assets/fonts/NLRTM-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/NLRTM-Regular.otf') format('otf'), url('../assets/fonts/NLRTM-Regular.woff2') format('woff2'),
    url('../assets/fonts/NLRTM-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
