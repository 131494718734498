@import 'variables';
@import './spacing.scss';

@mixin typography {
  color: $darkblue;

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }

  h1 {
    font-family: $font-family-strong;
    font-size: 36px;
    line-height: 36px;
    font-weight: normal;
    margin: 36px 0 18px 0;
  }

  h2 {
    font-family: $font-family-strong;
    font-size: 32px;
    line-height: 32px;
    margin: 52px 0 26px 0;
    font-weight: normal;
  }

  h3 {
    font-family: $font-family-strong;
    font-size: 28px;
    line-height: 28px;
    margin: 24px 0 12px 0;
    font-weight: normal;
  }

  h4 {
    font-family: $font-family-strong;
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }

  h5 {
    font-family: $font-family-strong;
    font-size: 20px;
    line-height: 25px;
    font-weight: normal;
    margin: 12px 0 6px 0;
  }

  h6 {
    font-family: $font-family-strong;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    margin: spacing(2) 0 spacing(1) 0;
  }

  p,
  li {
    font-family: $font-family-regular;
    font-size: 14px;
    line-height: 1.5;
    font-weight: normal;
    margin: 8px 0;
  }

  p.intro {
    font-family: $font-family-strong;
  }

  ul {
    $square-width: 20px;

    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: $square-width;

      &:before {
        display: inline-block;
        content: '\25AA '; // A little square.
        width: $square-width;
        margin-left: -$square-width;
        color: $lightblue;
      }
    }
  }

  a {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
}
