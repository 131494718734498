@import '../../../styles/variables';
@import '../../../styles/z-indices';
@import '../../../styles/mixins';
@import '../../../styles/spaced.scss';
@import '../../../styles/spacing.scss';
@import '../variables.scss';

.primaryMenu {
  $height: $header-height;
  $background-color: $header-background;

  border-bottom: 1px $header-menu-border-color solid;
  height: $height;
  width: 100%;

  background-color: $background-color;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    flex-direction: row;
    border-right: 1px $header-menu-border-color solid;

    > * {
      padding: 0 spacing(3);
      align-self: stretch;
      display: flex;
      & + * {
        border-left: 1px $header-menu-border-color solid;
      }
    }
  }

  .right {
    flex-direction: row-reverse;
  }

  .middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .hamburger,
  .action,
  .status,
  .toggle-quick-search {
    $font-size: $header-font-size;

    @include materialIconFontSize($font-size);

    height: $font-size;
    width: $font-size;
    padding: ($header-height - $font-size) / 2;
  }

  .hamburger,
  .action,
  .toggle-quick-search {
    cursor: pointer;
  }

  .hamburger {
    // A static grey circle:
    background-color: $white;
    color: $darkblue;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: radial-gradient(circle, $header-menu-border-color 50%, transparent 50%);
    text-align: center;

    // The circle is initially off-screen:
    background-position: 0 $height;

    // Make the circle grow slowly in 0.3 seconds.
    // Move it off-screen again after 0.3 seconds.
    // These transitions only happen after returning from :active.
    transition: background-size 0.3s, background-position 0.3s step-end;

    &:active {
      background-size: 0%;
      background-position: center;
      transition: background-size 0s, background-position 0s;
    }
  }

  .action,
  .status {
    @include actionMenuItem;
  }

  .websocketStatus {
    width: auto;
  }

  .tvModeAction {
    width: auto;
    display: none;
    @media (min-width: $breakpoint-medium) {
      display: block;
    }
  }
}
